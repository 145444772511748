<template></template>

<script>
  export default {
    name: "LandingPageAffiliate",
    mounted() {
      const vm = this,
          q = vm.$route.query.q;
      if (q) document.cookie = `affiliated_to=${q}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
      vm.$router.replace({name: 'home'})
    }
  }
</script>